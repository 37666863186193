<template>
  <div class="bonuses">
    <PromoItem
      class="bonuses__head"
      :color="promo.color"
      :title="promo.title"
      :images="promo.images"
    />
    <div class="bonuses__body">
      <Collapse color="grey">
        <template #head>Что за скидка</template>
        <template #body>
          <div class="bonuses__grid">
            <ContentNumber>
              <img
                src="@/assets/32/discount.svg"
                width="32"
                height="32"
                alt=""
              />
            </ContentNumber>
            50% бессрочная скидка на тарифы «Мой онлайн», «Мой онлайн+», «Везде
            онлайн» и «Black» и «Хватит!»
          </div>
        </template>
      </Collapse>
      <Collapse color="grey">
        <template #head>Для кого</template>
        <template #body>
          <div class="bonuses__grid">
            <ContentNumber>
              <img
                src="@/assets/32/invite-friends.svg"
                width="32"
                height="32"
                alt=""
              />
            </ContentNumber>
            Это программа лояльности продавцов розничной сети, которые являются
            абонентами t2
          </div>
        </template>
      </Collapse>
      <Collapse color="grey">
        <template #head>Как стать участником</template>
        <template #body>
          <div class="bonuses__grid">
            <ol>
              <li>Сообщите о своем желании торговому представителю t2</li>
              <li>
                Торговый представитель заполнит заявку на скидку. Понадобятся
                Ф.И.О. и номер телефона
              </li>
              <li>
                В течение двух недель вы получите SMS о присоединении к
                программе лояльности
              </li>
            </ol>
          </div>
        </template>
      </Collapse>
      <Collapse color="grey">
        <template #head>Особенности</template>
        <template #body>
          <div class="bonuses__grid">
            <ul>
              <li>
                Скидка действует до тех пор, пока вы работаете на точке,
                продающей услуги t2, и&nbsp;пользуетесь&nbsp;номером t2
              </li>
              <li>
                Скидка по программе лояльности не суммируется с другими скидками
                на абонентскую плату
              </li>
              <li>
                При одновременном подключении разных скидок будет действовать
                наибольшая из них
              </li>
            </ul>
          </div>
        </template>
      </Collapse>
    </div>
  </div>
</template>

<script setup>
import Collapse from "./Collapse.vue";
import ContentNumber from "./ContentNumber.vue";
import PromoItem from "./PromoItem.vue";

const promo = {
  color: "secondary",
  title: `бонусы для продавцов`,
  images: [
    require("@/assets/bonuses/m.webp"),
    require("@/assets/bonuses/d.webp"),
  ],
};
</script>

<style lang="scss" scoped>
.bonuses {
  margin-top: var(--widthGapH);
  display: grid;
  gap: var(--widthGapH);
  margin-bottom: var(--widthGapV);
  &__body {
    display: grid;
    gap: 8px;
  }
  &__grid {
    display: grid;
    gap: var(--widthGapV);
  }
}
</style>
